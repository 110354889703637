/**
 * Copyright (C) Sitevision AB 2002-2021, all rights reserved
 *
 * @author Sjostrand
 */
(function ($) {
  $.fn.pagination = function (options) {
    if (typeof options === 'number') {
      // Possible attempt to use old version plugin.
      // Fall back to no-js pagination.
      return;
    }

    const container = $(this);
    const paginationLabel = options.paginationLabel;
    let currentPage = 1;
    let $el = $(
      `<div class="pagination" role="navigation" aria-label="${paginationLabel}"></div>`
    );

    const pagination = {
      initialize: function () {
        $el = this.render();
        container.html($el);
        $el.on('click', 'a', (event) => this.handleClick(event));
      },

      handleClick: function (event) {
        event.preventDefault();
        const $target = $(event.target);
        const pageNumber = $target.data('page');
        const $container = $($target.attr('href'));

        switch (pageNumber) {
          case 'prev':
            currentPage--;
            break;
          case 'next':
            currentPage++;
            break;
          default:
            currentPage = pageNumber;
        }

        const url = options.baseUrl + (currentPage - 1) * options.hitsPerPage;

        sv.PortletUtil.doGetWithFade(`.${options.hitContainer}`, url, () => {
          this.render();
          $container[0].scrollIntoView();
        });
      },

      render: function () {
        const pagesOnEachSide = 2; //two on each side
        const totalPages = this.getTotalPages();
        let rangeStart = currentPage - pagesOnEachSide;
        let rangeEnd = currentPage + pagesOnEachSide;

        if (rangeEnd > totalPages) {
          rangeEnd = totalPages;
          rangeStart = totalPages - pagesOnEachSide * 2;
          rangeStart = rangeStart < 1 ? 1 : rangeStart;
        }

        if (rangeStart <= 1) {
          rangeStart = 1;
          rangeEnd = Math.min(pagesOnEachSide * 2 + 1, totalPages);
        }

        $el.html(this.generateHTML(rangeStart, rangeEnd, totalPages));
        return $el;
      },

      generateHTML: function (rangeStart, rangeEnd, totalPages) {
        const linkTarget = `#${options.hitContainer.replace('hits', 'result')}`;
        const prevText = options.text.prev;
        const nextText = options.text.next;
        const ariaPrevDesc = options.text.goToPrevious;
        const ariaNextDesc = options.text.goToNext;
        const goToPageText = options.text.goToPage;

        let html = '';
        if (currentPage <= 1) {
          html += `<span class="current prev" aria-label="${ariaPrevDesc}">&laquo; ${prevText}</span>`;
        } else {
          html += `<a href="${linkTarget}" class="prev" aria-label="&laquo; ${goToPageText} ${prevText}" data-page="prev">&laquo; <span class="sv-visuallyhidden">${goToPageText} </span>${prevText}</a>`;
        }

        html += this.generatePagingHTML(rangeStart, rangeEnd, totalPages);
        if (currentPage >= totalPages) {
          html += `<span class="current next" aria-label="${ariaNextDesc}">${nextText}</span>`;
        } else {
          html += `<a href="${linkTarget}" class="next" aria-label="${goToPageText} ${nextText} &raquo;" data-page="next"><span class="sv-visuallyhidden">${goToPageText} </span>${nextText} &raquo;</a>`;
        }

        return html;
      },

      generatePagingHTML: function (rangeStart, rangeEnd, totalPages) {
        const linkTarget = `#${options.hitContainer.replace('hits', 'result')}`;
        const currentText = options.text.currentPage;
        const goToPageText = options.text.goToPage;

        let html = '';
        let i = 1;

        if (rangeStart < 2) {
          for (i = 1; i < rangeStart; i++) {
            if (i === currentPage) {
              html += `<span aria-label="${currentText} ${i}" aria-current="true" class="current">${i}</span>`;
            } else {
              html += `<a href="${linkTarget}" aria-label="${goToPageText} ${i}" data-page="${i}"><span class="sv-visuallyhidden">${goToPageText} </span>${i}</a>`;
            }
          }
        } else {
          html += `<a href="${linkTarget}" aria-label="${goToPageText} ${i}" class="sp" data-page="${i}"><span class="sv-visuallyhidden">${goToPageText} </span>${i}</a>`;
          html += `<span>...</span>`;
        }

        for (i = rangeStart; i <= rangeEnd; i++) {
          if (i === currentPage) {
            html += `<span aria-label="${currentText} ${i}" aria-current="true" class="current">${i}</span>`;
          } else {
            html += `<a href="${linkTarget}" aria-label="${goToPageText} ${i}" data-page="${i}"><span class="sv-visuallyhidden">${goToPageText} </span>${i}</a>`;
          }
        }

        if (rangeEnd >= totalPages - 2) {
          for (i = rangeEnd + 1; i <= totalPages; i++) {
            html += `<a href="${linkTarget}" aria-label="${goToPageText} ${i}" data-page="${i}"><span class="sv-visuallyhidden">${goToPageText} </span>${i}</a>`;
          }
        } else {
          html += `<span>...</span>`;
          html += `<a href="${linkTarget}" aria-label="${goToPageText} ${totalPages}"class="ep" data-page="${totalPages}"><span class="sv-visuallyhidden">${goToPageText} </span>${totalPages}</a>`;
        }

        return html;
      },

      getTotalPages: function () {
        return Math.ceil((options.hitCount || 0) / options.hitsPerPage);
      },
    };

    pagination.initialize();
    return this;
  };
})(window.jQuery);
